const types = {
	SET_AUTHORIZED: 0,
	SET_ME: 1,
	SET_SERVERS: 2,
	SET_POPUP: 3,
	SET_NOTIFICATIONS: 4,
	SET_SERVER_WIPES: 5
};

export default types;
