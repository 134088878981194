import TYPES from './types.js';

const reducer = (state, { type, data }) => {
	switch (type) {
		case TYPES.SET_AUTHORIZED:
			return {
				...state,
				authorized: Boolean(data)
			};
		case TYPES.SET_ME:
			return {
				...state,
				me: data
			};
		case TYPES.SET_SERVERS:
			return {
				...state,
				servers: data.servers || state.servers,
				totalNetworkPlayers: data.playerCount || state.totalNetworkPlayers || 0,
				serversError: data.error || null
			};
		case TYPES.SET_SERVER_WIPES:
			return {
				...state,
				serverWipes: data
			};	
		case TYPES.SET_POPUP:
			return {
				...state,
				popup: data
			};
		case TYPES.SET_NOTIFICATIONS:
			return {
				...state,
				notifications: data
			}
		default:
			return state;
	}
};

export default reducer;
