import axios from 'axios';

const api = axios.create({
	baseURL: `${window.location.origin.replace('rustinity', 'rusticated').replace('limitlessrust', 'rusticated')}/api/v2`
});

api.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response.status === 401 && !error.response.config.url.includes('stats.')) {
			window.localStorage.removeItem('v2authToken');
			window.location.reload();
		}

		return Promise.reject(error);
	}
);

function setupCf() {
	let cf = localStorage.getItem('cf');

	if (!cf) {
		var req = new XMLHttpRequest();
		req.open('GET', '/cf', false);
		req.send(null);

		cf = req.response;
		localStorage.setItem('cf', cf);
	}

	const parsed = JSON.parse(cf);
	for (let header in parsed) {
		api.defaults.headers[header] = parsed[header];
	}
}

if (window.location.origin.includes('test.')) {
	setupCf();
}

/**
 * Extracts a status and message string from an axios error
 * @param {*} err Axios error
 * @param {*} defaultMessage the string to return when error message cannot be extracted from request
 * @returns object containing status and message fields { status, message }
 */
export function extractErrorMessage(err, defaultMessage = 'A network error occurred. Please try again later.') {
	if (err.response && err.response.data && err.response.data.status && err.response.data.message) {
		if (err.response.data.message === 'jwt expired') {
			window.location.replace(`${window.location.origin}/login?referrer=${window.location.pathname}`);
			return;
		}

		return { status: err.response.data.status, message: err.response.data.message };
	} else if (err.response && err.response.status) {
		return { status: err.response.status, message: defaultMessage };
	}

	return { status: null, message: defaultMessage };
}

export default api;
