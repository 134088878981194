// Module imports.
import { useState, useEffect, useCallback } from 'react';
import api from '../util/api';
import useAsyncError from './useAsyncError.js';

const useApi = ({ method, url, params = {}, responseType = 'json' }, { callIfTruthy = true } = {}) => {
    // Set the state and other parameters.
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
    const throwError = useAsyncError();
    const paramsString = JSON.stringify(params);

    // Call api function using react callback hook.
    const callApi = useCallback(async (signal = null) => {
		if (!data || error) {
            setIsLoading(true);
        }
		
        setError(null);
        
        try {
            const response = await api({ 
                method, 
                url, 
                params, 
                responseType, 
                ...(signal && { signal }) 
            })
            if (!signal || !signal.aborted) {
                setResponse(response);
                setData(response.data);
                setIsLoading(false);
            }
        } catch (err) {
            if (!signal || !signal.aborted) {
                if (err.name === 'AbortError') return;
                setError(err);
                setIsLoading(false);
                throwError(err);
            }
        }

    }, [method, url, params, throwError, responseType])

    // Call api once on mount.
    useEffect(() => {
        const controller = new AbortController();
        
        if (callIfTruthy) {
            callApi(controller.signal);
        }
        
        return () => {
            controller.abort();
        };
        // Why is url not working above.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, paramsString, callIfTruthy])

    return { response, data, callApi, isLoading, error }
}

export default useApi;
