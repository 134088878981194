import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useStoreService } from "../../store";
import useApi from "../../hooks/useApi";
import RustyLoading from "../../component/RustyLoading";
import RustyError from "../../component/RustyError";

const ServiceAuth = () => {
	// Get the state from the store service.
	const StoreService = useStoreService();
	const { authorized, me } = StoreService.state;
	const [readyToRedirect, setReadyToRedirect] = useState(false);

	const linkedAccounts = me?.linkedAccounts ?? {};

	// Get the service name and search params from the URL .
	const { service } = useParams();
	const searchParam = new URLSearchParams(document.location.search);

	// Set the returnUrl to use when returning from the login site.
	const returnUrl = localStorage.getItem("return") || "/";

	// Define the bool to determine if it is worth calling the API on mount.
	const callOnMount =
		(!!me || service === "steam") &&
		window.location.search &&
		((service === "steam" && !authorized) ||
			((searchParam.get("code") || searchParam.get("oauth_token")) &&
				!linkedAccounts[service.toUpperCase()]));

	// Initialize the useApi hook.
	const { response, data, error, isLoading } = useApi(
		{
			method: "post",
			url: `/service-auth/${service}/verify${window.location.search}`,
		},
		{ callIfTruthy: callOnMount }
	);

	// Loads authorization for steam requests, or updates the user info for other requests.
	// TODO: Probably don't need this function because of updated redirect method.
	useEffect(() => {
		if (response) {
			if (service === "steam" && data) {
				StoreService.loadAuthorization(data.token);
			}
			StoreService.fetchMe();
			if (authorized) {
				setReadyToRedirect(true);
			}
		}
	}, [response, data, authorized]);

	// Loading, error with redirect, or successful redirect.
	return isLoading ? (
		<RustyLoading />
	) : error ? (
		<RustyError error={error} redirect={{ url: returnUrl }} border>
			{localStorage.removeItem("return")}
		</RustyError>
	) : (
		readyToRedirect && <RedirectLink url={returnUrl} />
	);
};

const RedirectLink = ({ url }) => {
	localStorage.removeItem("return");
	window.location = url;

	return (
		<div>
			If you are not automatically redirected <a href={url}>click here</a>
		</div>
	);
};

export default ServiceAuth;
